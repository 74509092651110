<template>
  <div class="more">
    <p class="title">关于矿机</p>
    <van-collapse :border="false" v-model="activeName" accordion>
      <van-collapse-item title="什么是矿机套餐" name="1">内容</van-collapse-item>
      <van-collapse-item title="24K矿机产品优势" name="2">内容</van-collapse-item>
      <van-collapse-item title="产品安全保障" name="3">内容</van-collapse-item>
      <van-collapse-item title="矿场信息介绍" name="4">内容</van-collapse-item>
      <van-collapse-item title="产品费用说明" name="5">内容</van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
export default {
  name: "more",
  data() {
    return {
      activeName: "1",
    }
  },
  created() {},
  methods: {},
  mounted() {},
  computed: {},
  components: {},
}
</script>
<style scoped lang="scss">
.more {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px 60px;
  box-sizing: border-box;
    text-align: left;
    background: #f9f9f9;
  .title {
    margin: 20px 0 10px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: 800;
    font-size: 20px;
    color: #333333;
    line-height: 28px;
  }
}

.van-collapse {
  border-radius: 8px;
  font-weight: 500;
font-size: 16px;
color: #333333;
}
</style>
